import React from "react"
import { Controller, useWatch } from "react-hook-form"
import { InputField } from "../../../../components/FormCommons"
import { Checkbox, FormControlLabel } from "@mui/material"

export const PromoCodesUsesAvailable = ({ control, setValue }) => {
  const isUnlimited = useWatch({
    control,
    name: "unlimited"
  })

  return (
    <div className="flex flex-col md:flex-row gap-2">
      <Controller
        name={`uses_available`}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <InputField
            type="number"
            label="Liczba użyć"
            value={value}
            onChange={(e) => {
              if (!isUnlimited) {
                const val =
                  e.target.value === "" ? "" : parseInt(e.target.value, 10)
                onChange(val)
              }
            }}
            disabled={isUnlimited}
            error={!!error}
            helperText={error ? error.message : ""}
            inputProps={{ min: -1 }}
            id={`uses_available`}
          />
        )}
      />

      <Controller
        name="unlimited"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(e) => {
                  onChange(e.target.checked)
                  if (e.target.checked) {
                    setValue("uses_available", -1)
                  } else {
                    setValue("uses_available", "")
                  }
                }}
                color="primary"
              />
            }
            label="Nielimitowane"
          />
        )}
      />
    </div>
  )
}
