import { Button, CircularProgress, Dialog, InputAdornment } from "@mui/material"
import { Box } from "@mui/system"

import { useTranslation } from "react-i18next"

export const PromoCodesButtons = ({
  isSaving,
  isValid,
  onClose
}: {
  isSaving: boolean
  isValid: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-row mb-4 mt-4">
        <Button
          onClick={() => {
            onClose()
          }}
        >
          {t("cancel", "Anuluj")}
        </Button>
        <Box flex={1} />
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSaving || !isValid}
        >
          {isSaving ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            t("save", "Zapisz")
          )}
        </Button>
      </div>
    </>
  )
}
