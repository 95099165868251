import { Routes, Route } from 'react-router-dom'
import { ApiTable } from '../../../components/table/ApiTable'
import axios from 'axios'
import Toolbar from '../Toolbar/Toolbar'
import { useSearchText } from '../useSearchText'
import { useState } from 'react'
import { Button, IconButton, Menu, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { AddPromoCode } from './AddPromoCode'
import { EditPromoCode } from './EditPromoCode'
import { FormattedDate } from 'react-intl'

const mapPromoCode = promoCode => {
  return {
    ...promoCode,
    price_value: promoCode.price_value
      ? promoCode.price_value / 100
      : promoCode.price_value
  }
}

const listPromoCodes = async () => {
  const url =
    process.env.REACT_APP_WAKACJE_API_URL + `/promo-codes/v1.0?limit=30&after=0`

  const res = await axios.get(url)

  const result = {
    ...res.data,
    data: res.data.data.map(mapPromoCode)
  }

  console.log({ result })

  return result
}

const ActionsMenu = ({ onEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    handleClose()
    onEdit()
  }

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        style={{ width: '200px' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>Edytuj</MenuItem>
      </Menu>
    </>
  )
}

const SuperAdminPromoCodesTable = ({ queryParams, onEdit }) => {
  const columns = [
    {
      header: 'Kod rabatowy',
      field: 'code',
      cell: (row: any) => <div>{row.code}</div>
    },
    {
      header: 'Ważny do',
      field: 'valid_to',
      cell: (row: any) => (
        <>
          <div className='leading-none font-bold text-xs'>
            <p className='whitespace-nowrap'>
              <FormattedDate value={row.valid_to} />{' '}
            </p>
          </div>
        </>
      )
    },
    {
      header: 'Pozostała liczba użyć',
      field: 'uses_available',
      cell: (row: any) => (
        <div>
          {row.uses_available === -1 ? 'Nielimitowana' : row.uses_available}
        </div>
      )
    },
    {
      header: 'Rabat',
      cell: (row: any) => (
        <div>
          {row.percentage_value
            ? `${row.percentage_value} %`
            : row?.price_value && row?.currency
            ? `${row.price_value} ${row.currency}`
            : ''}
        </div>
      )
    },
    {
      header: '',
      field: 'actions',
      cell: row => <ActionsMenu onEdit={() => onEdit(row)} />
    }
  ]

  return (
    <ApiTable
      columns={columns}
      listApi={listPromoCodes}
      showExpandColumn
      queryParams={queryParams}
    ></ApiTable>
  )
}

export const SuperAdminPromoCodesList = () => {
  const { setSearchTextDebounced, queryParams } = useSearchText()
  const mergedQueryParams = { ...queryParams }

  const [isAddPromoCodeModalOpen, setIsAddPromoCodeModalOpen] = useState(false)
  const [isEditPromoCodeModalOpen, setIsEditPromoCodeModalOpen] =
    useState(false)
  const [selectedPromoCode, setSelectedPromoCode] = useState(null)

  const handleAddPromoCodeOpenModal = () => setIsAddPromoCodeModalOpen(true)
  const handleAddPromoCodeCloseModal = () => setIsAddPromoCodeModalOpen(false)

  const handleEditPromoCodeOpenModal = promoCode => {
    setSelectedPromoCode(promoCode)
    setIsEditPromoCodeModalOpen(true)
  }

  const handleEditPromoCodeCloseModal = () => {
    setSelectedPromoCode(null)
    setIsEditPromoCodeModalOpen(false)
  }

  return (
    <>
      <Toolbar
        setSearchTextDebounced={setSearchTextDebounced}
        api={listPromoCodes}
        queryParams={mergedQueryParams}
      >
        <Button
          variant='outlined'
          sx={{
            borderWidth: '0',
            height: '28px',
            ':hover': { borderWidth: '0' }
          }}
          onClick={handleAddPromoCodeOpenModal}
        >
          Dodaj kod rabatowy
        </Button>
      </Toolbar>

      <AddPromoCode
        open={isAddPromoCodeModalOpen}
        onClose={handleAddPromoCodeCloseModal}
      />

      <EditPromoCode
        open={isEditPromoCodeModalOpen}
        promoCodeData={selectedPromoCode}
        onClose={handleEditPromoCodeCloseModal}
      />

      <Routes>
        <Route
          index
          element={
            <SuperAdminPromoCodesTable
              queryParams={mergedQueryParams}
              onEdit={handleEditPromoCodeOpenModal}
            />
          }
        />
      </Routes>
    </>
  )
}
