import { useState } from 'react'
import { useAuth } from 'wakacje-web/src/auth/AuthContext'
import { MojeButton } from 'wakacje-web/src/components/MojeButton'
import { LinkUtils } from 'wakacje-web/src/shared/LinkUtils'
import { useAuthContext } from '../../contexts/auth-context'
import { useTranslation, Trans } from 'react-i18next'

export const CompanyRegistrationConfirmEmail = () => {
  const { currentUser } = useAuthContext()
  const { user, sendVerificationEmail } = useAuth()
  const [isSending, setIsSending] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const { t, i18n } = useTranslation()

  const handleResend = async () => {
    setIsSending(true)
    console.log(user, user)
    const href = LinkUtils.getAdminHref()
    await sendVerificationEmail(href, i18n.language)
    setIsSending(false)
    setEmailSent(true)
  }

  return (
    <div
      className='flex flex-col items-center pt-8 w-[90%] lg:w-[440px] mx-auto'
      id='registration-box'
    >
      <div className='text-center mb-8 text-white font-bold text-4xl mt-5'>
        {t(
          'company_registration_confirm_email.check_your_email',
          'Sprawdź pocztę'
        )}
      </div>

      <div className='text-center mb-8 text-white font-semibold text-base '>
        <Trans
          i18nKey='company_registration_confirm_email.email_sent_to'
          values={{ email: currentUser?.email }}
          components={{ strong: <strong /> }}
        >
          Na podany przez Ciebie adres mailowy{' '}
          <strong>{currentUser?.email}</strong> została wysłana wiadomość z
          linkiem aktywacyjnym. Potwierdź swój adres mailowy i dokończ
          rejestrację.
        </Trans>
      </div>

      <div className='text-center mb-8 '>
        <img
          src='assets/registration/email-circle.svg'
          alt={t('company_registration_confirm_email.email_alt', 'email')}
          className='h-[108px] w-[108px]'
        />
      </div>
      <div className='text-center mb-8 text-white font-bold text-xl '>
        {t(
          'company_registration_confirm_email.cant_see_email',
          'Nie widzisz wiadomości?'
        )}
      </div>

      <div className='text-center mb-8 text-white font-semibold text-base '>
        <Trans
          i18nKey='company_registration_confirm_email.email_sending_may_take_time'
          components={{
            a: (
              <a
                href='mailto:kontakt@fajnyczas.pl'
                className='underline text-fc-primary'
              />
            )
          }}
        >
          Wysłanie wiadomości może chwile potrwać. Sprawdź spam. Jeśli nadal
          masz problem skontaktuj się z nami poprzez <a>kontakt@fajnyczas.pl</a>
        </Trans>
      </div>

      <div className='text-center mb-8'>
        {!emailSent && (
          <MojeButton onClick={handleResend} isSaving={isSending}>
            {t(
              'company_registration_confirm_email.resend_email',
              'Wyślij email ponownie'
            )}
          </MojeButton>
        )}
        {emailSent && (
          <div>
            {t(
              'company_registration_confirm_email.email_has_been_sent',
              'Email został wysłany'
            )}
          </div>
        )}
      </div>
    </div>
  )
}
