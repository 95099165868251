import React, { useEffect } from "react"
import { Control, Controller } from "react-hook-form"
import { InputField } from "../../../../components/FormCommons"

export const PromoCodesValidTo = ({
  control,
  setValue,
  validTo
}: {
  control: Control
  setValue: any
  validTo
}) => {
  useEffect(() => {
    if (validTo) {
      setValue("valid_to", validTo)
    } else {
      setValue("valid_to", "")
    }
  }, [control])

  return (
    <div className="flex flex-col md:flex-row gap-2">
      <Controller
        name="valid_to"
        control={control}
        rules={{
          required: "Data jest wymagana"
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const val =
            value != null && value.length > 10 ? value.substr(0, 10) : value
          return (
            <InputField
              type="date"
              onChange={onChange}
              value={val || ""}
              label="Ważny do"
              InputLabelProps={{ shrink: true }}
              id="valid_to"
              error={!!error}
              helperText={error ? error.message : ""}
              inputProps={{ required: true }}
            />
          )
        }}
      />
    </div>
  )
}
