// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app"
import { getAuth } from "@firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAO0r1f4znVowoTLnjtICbpmkaB60wHFYE",
  authDomain: "fajnyczas-3afcb.firebaseapp.com",
  projectId: "fajnyczas-3afcb",
  storageBucket: "fajnyczas-3afcb.appspot.com",
  messagingSenderId: "57799278866",
  appId: "1:57799278866:web:6cf49a9ea730d1f7a733cb",
}

initializeApp(firebaseConfig)
export const auth = getAuth()
auth.useDeviceLanguage();
