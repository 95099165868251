import { Trip } from "../../../types/wakacje-types"
import { useFormState, useFormContext, Controller } from "react-hook-form"
import { addDays, formatISO, differenceInDays } from "date-fns"
import { Heading3, InputField } from "../../../components/FormCommons"
import { MojeTextInput } from "../../../components/ui/MojeTextInput"
import { TripFormSectionParticipants } from "./TripFormDayCamp"

import {
  PRODUCT_TYPE_DAYCAMP,
  PRODUCT_TYPE_SCHOOL_TRIP
} from "wakacje-web/src/utils/ProductUtils"
import { useTranslation } from "react-i18next"
import { CampFees } from "../../../components/Fees/CampFees/CampFees"

export type TripValues = Pick<
  Trip,
  | "name"
  | "start_date"
  | "end_date"
  | "available_spots"
  | "full_price"
  | "advance_price"
  | "payment_due_date"
>

const TripFormDayCampFields = () => {
  return (
    <>
      <p className="font-bold my-[10px] text-sm">Podsumowanie: 7 dni</p>
    </>
  )
}

export const TripForm = ({ index, productType, productCurrency }) => {
  const { t } = useTranslation()
  const { setValue, control, watch, register } = useFormContext()
  const { errors } = useFormState()

  const startDate = watch("start_date")

  const endDate = watch("end_date")
  const registerStartDate = register("start_date")
  const name = watch("name")

  return (
    <div>
      <Heading3>Termin</Heading3>
      <MojeTextInput
        {...register("name")}
        label="*Nazwa turnusu"
        id={`name-${index}`}
        error={errors.name?.message}
        fullWidth
      />
      <div className="flex justify-items-stretch gap-4">
        <div className="w-[207px]">
          <InputField
            {...registerStartDate}
            type="date"
            InputLabelProps={{ shrink: true }}
            label={`*${
              productType === PRODUCT_TYPE_DAYCAMP ? "Termin Od" : "Początek"
            }`}
            id={`start_date-${index}`}
            onChange={(e: any) => {
              if (endDate) {
                const newStart = new Date(e.target.value)
                const currentStart = new Date(startDate)
                const currentEnd = new Date(endDate)
                const diff = differenceInDays(newStart, currentStart)
                setValue(
                  "end_date",
                  formatISO(addDays(currentEnd, diff), {
                    representation: "date"
                  })
                )
              }
              registerStartDate.onChange(e)
            }}
          />
        </div>
        <div className="w-[207px]">
          <InputField
            {...register("end_date")}
            InputLabelProps={{ shrink: true }}
            disabled={!startDate}
            type="date"
            id={`end_date-${index}`}
            label={`*${
              productType === PRODUCT_TYPE_DAYCAMP ? "Termin Do" : "Koniec"
            }`}
            inputProps={{
              min: formatISO(
                addDays(startDate ? new Date(startDate) : new Date(), 0),
                {
                  representation: "date"
                }
              )
            }}
            error={new Date(startDate) > new Date(endDate)}
          />
        </div>
      </div>
      <div className="w-[207px]">
        <MojeTextInput
          {...register("available_spots")}
          type="number"
          label="*Ilość dostępnych miejsc"
          id={`available_spots-${index}`}
          error={errors.name?.message}
          fullWidth
        />
      </div>
      {productType === PRODUCT_TYPE_DAYCAMP.value && <TripFormDayCampFields />}
      {productType === PRODUCT_TYPE_SCHOOL_TRIP.value && (
        <>
          <Heading3>Liczba miejsc</Heading3>
          <MojeTextInput
            {...register("available_spots")}
            type="number"
            id={`available_spots-${index}`}
            label="*Liczba miejsc"
            inputProps={{
              min: 0
            }}
            error={errors.available_spots?.message}
            fullWidth
          />
        </>
      )}
      <TripFormSectionParticipants
        register={register}
        index={index}
        watch={watch}
      />
      <div className="mt-4">
        <Heading3>Opłaty</Heading3>
      </div>

      <div className="flex justify-items-stretch gap-4">
        {productType === PRODUCT_TYPE_DAYCAMP.value && (
          <div>
            <MojeTextInput
              {...register("reservation_min_days", { required: true })}
              type="number"
              id={`reservation_min_days-${index}`}
              label="*Rezerwacja na minimum"
              inputProps={{
                min: 0
              }}
              error={errors.reservation_min_days?.message}
              fullWidth
              placeholder="5 dni"
            />
          </div>
        )}
      </div>
      <CampFees control={control} productCurrency={productCurrency} />
    </div>
  )
}
