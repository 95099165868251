import { Dialog } from '@mui/material'

import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { PromoCodesCode } from './PromoCodesDialogFields/PromoCodesCode'
import { SectionTitle } from '../../../components/FormCommons'
import { PromoCodesValidTo } from './PromoCodesDialogFields/PromoCodesValidTo'
import { PromoCodesUsesAvailable } from './PromoCodesDialogFields/PromoCodesUsesAvailable'
import { PromoCodesPromoPrice } from './PromoCodesDialogFields/PromoCodesPromoPrice'
import { PromoCodesButtons } from './PromoCodesDialogFields/PromoCodesButtons'

export const SuperAdminPromoCodesDialog = ({
  open,
  promoCodeData,
  onClose,
  headerTitle,
  onSubmit
}: {
  open: boolean
  promoCodeData?: any
  onClose: () => void
  headerTitle: string
  onSubmit: (data: any) => void
}) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  const [isSaving, setIsSaving] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: {
      id: promoCodeData?.id || '',
      code: promoCodeData?.code || '',
      valid_to: promoCodeData?.valid_to || '',
      uses_available: promoCodeData?.uses_available ?? '',
      unlimited: promoCodeData?.uses_available === -1,
      percentage_value: promoCodeData?.percentage_value || '',
      price_value: promoCodeData?.price_value || '',
      currency: promoCodeData?.currency || ''
    }
  })

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
    reset
  } = formMethods

  const { t } = useTranslation()

  useEffect(() => {
    reset({
      id: promoCodeData?.id || '',
      code: promoCodeData?.code || '',
      valid_to: promoCodeData?.valid_to || '',
      uses_available: promoCodeData?.uses_available ?? '',
      unlimited: promoCodeData?.uses_available === -1,
      percentage_value: promoCodeData?.percentage_value || '',
      price_value: promoCodeData?.price_value || '',
      currency: promoCodeData?.currency || ''
    })
  }, [promoCodeData, reset])

  const onFormSubmit = async (data: any) => {
    if (isSaving) {
      return
    }
    const isValid = await formMethods.trigger()

    if (!isValid) {
      return
    }

    try {
      setIsSaving(true)

      await onSubmit(data)

      enqueueSnackbar(t('ui.trips.success_save', 'Zapisano!'), {
        variant: 'success'
      })

      setIsSaving(false)
      onClose()
    } catch (err) {
      setIsSaving(false)

      console.error('Error saving')
      enqueueSnackbar(
        t('ui.trips.error_save', 'Problem z zapisem ') + (err as any)?.message,
        {
          variant: 'error'
        }
      )
    }
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: md ? '564px' : '300px',
          width: '100%',
          borderRadius: '8px'
        }
      }}
      onClose={onClose}
    >
      {
        <div className='flex flex-col gap-4 pt-6 pb-10 px-10'>
          <div className=''>
            <div className=' flex justify-end cursor-pointer' onClick={onClose}>
              <Close width={16} />
            </div>
            <section>
              <SectionTitle useBlueBackground={false}>
                {headerTitle}
              </SectionTitle>
              <form onSubmit={handleSubmit(onFormSubmit)} className='p-2'>
                <PromoCodesCode
                  control={control as any}
                  setValue={setValue}
                  promoCode={promoCodeData?.code}
                />

                <PromoCodesValidTo
                  control={control as any}
                  setValue={setValue}
                  validTo={promoCodeData?.valid_to}
                />
                <PromoCodesUsesAvailable
                  control={control}
                  setValue={setValue}
                />

                <PromoCodesPromoPrice
                  control={control}
                  setValue={setValue}
                  percentageValue={promoCodeData?.percentage_value}
                  priceValue={promoCodeData?.price_value}
                  currency={promoCodeData?.currency}
                />

                <PromoCodesButtons
                  isSaving={isSaving}
                  isValid={isValid}
                  onClose={onClose}
                />
              </form>
            </section>
          </div>
        </div>
      }
    </Dialog>
  )
}
