import { useReducer, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { MojeButton } from '../MojeButton'
import { WakacjeTextInput } from '../../shared/components/WakacjeTextInput'
import { useAuth } from '../../auth/AuthContext'
import { ActionCodeSettings } from '@firebase/auth'
import { LinkUtils } from '../../shared/LinkUtils'
import { useTranslation } from 'next-i18next'

const Actions = {
  EMAIL_SENT: 'EMAIL_SENT'
}

const Step = {
  ENTER_EMAIL: 'ENTER_EMAIL',
  EMAIL_SENT: 'EMAIL_SENT'
}

const logInSchema = Yup.object({
  email: Yup.string()
    .email('E-mail nie jest poprawny')
    .required('Wprowadź poprawny e-mail')
})

const reducer = (state, action) => {
  switch (action.type) {
    case Actions.EMAIL_SENT:
      return { ...state, loading: false, step: Step.EMAIL_SENT }
      return {
        ...state,
        loading: false,
        step: Step.ENTER_EMAIL,
        errorCode: action.errorCode
      }
    default:
      return state
  }
}
export const ResetPasswordForm = () => {
  const { i18n } = useTranslation('common')
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    step: Step.ENTER_EMAIL
  })
  const [isSaving, setIsSaving] = useState(false)

  const defaultValues = {
    email: ''
  }
  const formMethods = useForm<any>({
    resolver: yupResolver(logInSchema),
    mode: 'onChange',
    defaultValues
  })
  const {
    handleSubmit,
    getValues,
    watch,
    formState: { errors, touchedFields },
    register
  } = formMethods

  const { sendPasswordResetEmail } = useAuth()

  const values = getValues()

  const onSubmit = async values => {
    const isValid = await formMethods.trigger()
    if (!isValid) {
      return
    }
    setIsSaving(true)
    if (state.step === Step.ENTER_EMAIL) {
      try {
        const href = LinkUtils.getAdminHref()
        const actionCodeSettings: ActionCodeSettings = {
          url: href + '/login?source=reset-password'
        }
        var res = await sendPasswordResetEmail(
          values.email,
          i18n.language,
          actionCodeSettings
        )
        dispatch({ type: Actions.EMAIL_SENT })
      } catch (error) {}
    } else if (state.step === Step.EMAIL_SENT) {
    }
    setIsSaving(false)
  }
  return (
    <div>
      {state.step === Step.ENTER_EMAIL && (
        <div className='my-4 text-center'>
          Wpisz adres e-mail podany podczas rejestracji konta, na który wyślemy
          instrukcje resetowania hasła.
        </div>
      )}

      <FormProvider {...{ ...formMethods }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {state.step == Step.ENTER_EMAIL && (
            <div className='mb-4'>
              <WakacjeTextInput
                id='email'
                label='Email'
                fullWidth
                {...register('email')}
                errors={errors}
                touched={touchedFields?.email}
                valid={!errors.email}
                value={watch('email')}
                disabled={isSaving}
              />
            </div>
          )}

          {state.step == Step.EMAIL_SENT && (
            <>
              <div className='mb-8'>
                <div className='mb-8'>
                  Link do zrestartowania hasła został wysłany na wskazany adres
                </div>
                <div className='text-center font-bold'>{values.email}</div>
              </div>
            </>
          )}
          {state.step != Step.EMAIL_SENT && (
            <div>
              <MojeButton
                type='submit'
                variant='contained'
                color='primary'
                label='Resetuj hasło'
                isSaving={isSaving}
              />
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  )
}
