import { SuperAdminPromoCodesList } from "./SuperAdminPromoCodesList"

export const SuperAdminPromoCodes = () => {
  return (
    <>
      <h1 className="text-[32px] font-bold">Kody Rabatowe</h1>

      <div className="w-full">
        <SuperAdminPromoCodesList />
      </div>
    </>
  )
}
