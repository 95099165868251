// src/pages/PromoCodesPromoPrice.js
import React, { useEffect, useRef } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { InputField } from '../../../../components/FormCommons'
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useCurrency } from '../../../../hooks/useCurrency'

export const PromoCodesPromoPrice = ({
  control,
  setValue,
  currency,
  percentageValue,
  priceValue
}) => {
  const { t } = useTranslation()
  const { CURRENCY_LIST } = useCurrency()

  console.log({ priceValue })

  // Obserwuj wybrany typ rabatu
  const discountType = useWatch({
    control,
    name: 'discount_type',
    defaultValue: 'percentage'
  })

  const isFirstRender = useRef(true)

  // Inicjalizacja pól na podstawie props (tylko na pierwszym renderze)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false

      if (priceValue && currency) {
        setValue('discount_type', 'price')
        setValue('price_value', priceValue)
        setValue('currency', currency)
        setValue('percentage_value', '')
      } else if (percentageValue) {
        setValue('discount_type', 'percentage')
        setValue('percentage_value', percentageValue)
        setValue('price_value', '')
        setValue('currency', '')
      } else {
        setValue('discount_type', 'percentage')
        setValue('percentage_value', '')
        setValue('price_value', '')
        setValue('currency', '')
      }
    }
  }, [percentageValue, priceValue, currency, setValue])

  const handleDiscountTypeChange = event => {
    const newType = event.target.value
    if (newType === 'percentage') {
      setValue('price_value', '')
      setValue('currency', '')
    } else if (newType === 'price') {
      setValue('percentage_value', '')
    }
  }

  return (
    <>
      <div className='flex flex-col md:flex-row gap-2 mt-4'>
        {/* Przełącznik Rabatu */}
        <FormControl component='fieldset'>
          <FormLabel component='legend'>
            {t('ui.promo.discount_type', 'Typ rabatu')}
          </FormLabel>
          <Controller
            name='discount_type'
            control={control}
            defaultValue='percentage'
            render={({ field }) => (
              <RadioGroup
                row
                {...field}
                onChange={e => {
                  field.onChange(e)
                  handleDiscountTypeChange(e)
                }}
              >
                <FormControlLabel
                  value='percentage'
                  control={<Radio />}
                  label={t('ui.promo.percentage_discount', 'Rabat procentowy')}
                />
                <FormControlLabel
                  value='price'
                  control={<Radio />}
                  label={t('ui.promo.price_discount', 'Rabat kwotowy')}
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </div>

      <div className='flex flex-col md:flex-row gap-2 mt-4'>
        {discountType === 'percentage' && (
          <Controller
            name='percentage_value'
            control={control}
            rules={{
              required: 'To pole jest wymagane',
              min: {
                value: 0,
                message: 'Rabat procentowy nie może być ujemny'
              },
              max: {
                value: 100,
                message: 'Rabat procentowy nie może przekraczać 100%'
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputField
                type='number'
                label={t('ui.promo.percentage_value', 'Wartość rabatu (%)')}
                value={value !== undefined && value !== null ? value : ''}
                onChange={e => {
                  const val =
                    e.target.value === '' ? '' : parseFloat(e.target.value)
                  onChange(val)
                }}
                error={!!error}
                helperText={error ? error.message : ''}
                inputProps={{ min: 0, max: 100 }}
              />
            )}
          />
        )}

        {discountType === 'price' && (
          <>
            <Controller
              name='price_value'
              control={control}
              rules={{
                required: 'To pole jest wymagane',
                min: {
                  value: 0,
                  message: 'Rabat kwotowy nie może być ujemny'
                }
              }}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => (
                <InputField
                  type='number'
                  label={t('ui.promo.price_value', 'Wartość rabatu (cena)')}
                  value={value !== undefined && value !== null ? value : ''}
                  onChange={e => {
                    const val =
                      e.target.value === '' ? '' : parseFloat(e.target.value)
                    onChange(val)
                  }}
                  error={!!error}
                  helperText={error ? error.message : ''}
                  inputProps={{ min: 0 }}
                />
              )}
            />

            <Controller
              name='currency'
              control={control}
              rules={{
                required: 'Waluta jest wymagana'
              }}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => (
                <FormControl fullWidth error={!!error}>
                  <InputField
                    select
                    label={t('ui.promo.currency', 'Waluta')}
                    value={value || ''}
                    onChange={e => onChange(e.target.value)}
                  >
                    {CURRENCY_LIST.map(({ currency, symbol }) => (
                      <MenuItem key={currency} value={currency}>
                        {currency} ({symbol})
                      </MenuItem>
                    ))}
                  </InputField>
                  {error && (
                    <span className='text-red-500 text-sm'>
                      {error.message}
                    </span>
                  )}
                </FormControl>
              )}
            />
          </>
        )}
      </div>
    </>
  )
}
