import { Control, Controller } from "react-hook-form"
import { InputField } from "../../../../components/FormCommons"
import { useEffect } from "react"

export const PromoCodesCode = ({
  control,
  setValue,
  promoCode
}: {
  control: Control
  setValue
  promoCode: string
}) => {
  useEffect(() => {
    if (promoCode && promoCode.trim() !== "") {
      setValue("code", promoCode)
    } else {
      setValue("code", "")
    }
  }, [promoCode, setValue])

  return (
    <>
      <Controller
        name={"code"}
        control={control}
        rules={{
          required: "Nazwa kodu rabatowego jest wymagana"
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <InputField
              onChange={onChange}
              value={value}
              label={"Kod rabatowy"}
              id={`code`}
            />
          )
        }}
      />
    </>
  )
}
